<template>
  <ContainerBasic
    v-if="blockData.image"
    :container-type="blockData.containerType"
    :class="isHeader ? '!mt-0 md:!mt-0' : ''"
  >
    <div
      class="md:h-[370px] h-[210px] relative overflow-hidden"
      :class="[
        blockData.containerType === EContainerType.FULL_WIDTH
          ? '!h-[260px]'
          : '',
      ]"
      :style="`height: ${props.customHeight}px !important`"
    >
      <ImageBasic
        :image-data="blockData.image"
        classes="w-full object-center object-cover z-[-10] overflow-hidden h-full"
      ></ImageBasic>
      <div
        v-if="
          blockData.image[isMobile ? 'mobile' : 'desktop']?.copyright !== null
        "
        class="max-w-[180px] bottom-[15px] right-[15px] md:right-[30px] text-right z-10 absolute ml-auto"
      >
        <span
          class="text-sm italic font-light text-white drop-shadow-xs font-roboto leading-xs"
          >&copy;
          {{
            blockData.image[isMobile ? 'mobile' : 'desktop']?.copyright
          }}</span
        >
      </div>
    </div>
    <div class="bg-activesection-base h-md" />
  </ContainerBasic>
</template>

<script lang="ts" setup>
import { EContainerType } from '@/@types/container-type';
import ContainerBasic from '@/components/components/container-basic.vue';
import ImageBasic from '@/components/components/core/basicImage/image-basic.vue';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import type { ContentHeaderData } from './blockType';

const props = defineProps({
  blockData: {
    type: Object as PropType<ContentHeaderData>,
    required: true,
    default: {} as ContentHeaderData,
  },
  isHeader: {
    type: Boolean,
    required: true,
    default: () => {
      return false;
    },
  },
  customHeight: {
    type: String,
    required: false,
    default: '',
  },
});

const isMobile = inject(mq_breakpointIsMobile);
</script>
